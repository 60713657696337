<template>
  <v-container class="px-0 pt-0" style="padding-bottom: 72px !important" fluid>
    <v-img
      :src="require('@/assets/feedback.png')"
      style="position: absolute"
    ></v-img>
    <v-container>
      <back-toolbar
        :title="$route.params.detailId ? 'review.heading' : 'review.appHeading'"
        style="background: transparent"
      ></back-toolbar>
      <v-img
        height="20vh"
        contain
        :src="require('@/assets/emoji.png')"
        width="75%"
        class="dp-block mx-auto mt-12"
      ></v-img>
    </v-container>
    <v-container>
      <v-col cols="12">
        <div class="Heading-Dark-H4-18-Center">{{ $t("review.rate") }}</div>
        <v-rating
          v-if="$route.params.detailId != 0"
          background-color="#c1c7ca"
          empty-icon="mdi-star-outline"
          full-icon="mdi-star-outline"
          length="5"
          size="55"
          v-model="detailRating"
          color="#fdbd2f"
          class="text-center mt-3"
        ></v-rating>
        <v-rating
          v-else
          background-color="#c1c7ca"
          empty-icon="mdi-star-outline"
          full-icon="mdi-star-outline"
          length="5"
          size="55"
          v-model="rating"
          color="#fdbd2f"
          class="text-center mt-3"
        ></v-rating>
      </v-col>
      <v-col cols="12">
        <div class="Heading-Dark-H4-18-Left-Align mb-2">
          {{ $t("review.comments") }}
        </div>
        <v-textarea
          solo
          v-if="$route.params.detailId != 0"
          :style="$store.state.settings.dark ? 'background-color:#1e1e1e;' : ''"
          :placeholder="$t('review.placeholder')"
          no-resize
          height="15vh"
          v-model="detailComment"
          hide-details
        ></v-textarea>
        <v-textarea
          v-else
          solo
          :style="$store.state.settings.dark ? 'background-color:#1e1e1e;' : ''"
          :placeholder="$t('review.placeholder')"
          no-resize
          height="15vh"
          v-model="comment"
          hide-details
        ></v-textarea>
        <v-btn
          class="gradient d-block mx-auto mt-6 Heading-White-H4-18-Center"
          height="48"
          min-width="150"
          color="primary"
          depressed
          @click="submitRating()"
          >{{ $t("review.submit") }}</v-btn
        >
      </v-col>
    </v-container>
  </v-container>
</template>
<script>
import BackToolbar from "@/components/app/common/BackToolbar";

export default {
  data() {
    return {
      detailRating: 0,
      detailComment: "",
      rating: this.$store.state.profile.data.rating || 0,
      comment: "",
    };
  },
  components: {
    BackToolbar,
  },
  computed: {},
  methods: {
    submitRating() {
      if (this.$route.params.detailId != 0) {
        this.$store.dispatch("recommendations/addUserRating", {
          rating: this.detailRating,
          comment: this.detailComment,
        });
      } else {
        this.$store.dispatch("profile/setPOCORating", {
          rating: this.rating,
          comment: this.comment,
        });
      }
      this.$router.back();
    },
  },
};
</script>
<style>
.v-rating .v-icon {
  padding: 0 !important;
}
</style>
